import { getCurrentLocale } from './src/utils/locales'
import WrapPageElement from './src/utils/WrapPageElement'

// Use FT Polyfill service https://cdn.polyfill.io/v3/ to add missing capabilities
// to old, but otherwise viable browsers. Intl is huge and this is by far the
// best/only viable way to load the minimum amount required for the browser.
// See: https://github.com/andyearnshaw/Intl.js/#intljs-and-ft-polyfill-service
//
// Likewise URL is super contentious and WHATWG's NPM package doesn't support old
// browsers. @babel/polyfill via Gatsby bundles core-js 2.6 which doesn't polyfill
// URL either. V3 does but it's impossible to upgrade before they do.
/** @type {import('gatsby').GatsbyBrowser['onClientEntry']} */
export const onClientEntry = () => {
  return new Promise((resolve, reject) => {
    window.__polyfillio__ = () => {
      resolve()
    }

    const features = []

    if (!('Intl' in window)) {
      const locale = getCurrentLocale(window.location.pathname)
      features.push(`Intl.~locale.${locale === 'en-US' ? 'en' : locale}`)
    }

    if (!hasURL()) {
      features.push('URL')
    }

    if (!('fetch' in window)) {
      features.push('fetch')
    }

    if (features.length) {
      const s = document.createElement('script')
      s.src = `https://polyfill.io/v3/polyfill.min.js?features=${features.join(
        ',',
      )}&flags=always&callback=__polyfillio__`
      s.async = true
      s.onerror = reject
      document.head.appendChild(s)
    } else {
      resolve()
    }
  })
}

const hasURL = () => {
  /*
   * Browsers may have:
   * No global URL object
   * URL with static methods only - may have a dummy constructor
   * URL with members except searchParams
   * Full URL API support
   */
  'use strict'

  try {
    const nativeURL = new window.URL('http://example.com')

    if ('href' in nativeURL && 'searchParams' in nativeURL) {
      const url = new URL('http://example.com')
      url.search = 'a=1&b=2'
      if (url.href === 'http://example.com/?a=1&b=2') {
        url.search = ''
        if (url.href === 'http://example.com/') {
          const sp1 = new window.URLSearchParams('a=1')
          const sp2 = new window.URLSearchParams(sp1)
          if (String(sp2) === 'a=1') {
            return true
          }
        }
      }
    }
    return false
  } catch (error) {
    return false
  }
}

/** @type {import('gatsby').GatsbyBrowser['wrapPageElement']} */
export const wrapPageElement = WrapPageElement
